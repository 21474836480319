import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import { Button } from '@core/Inputs';
import { appButtonType, appRoute } from '@utils/globalConstant';
import { Image } from '@core/Utils/Image';
import IosStore from '@theme/assets/img/ios-app.svg';
import AppStore from '@theme/assets/img/android-app.svg';

import './style.scss';
import { useCustomer } from '@context/MobileOne';

export function AppDownloadAlert({ welcomePopup, setWelcomePopup, onLogoutPress, isUserLoggedIn }) {
  //  Constants

  // States
  const [isOpen, setIsOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(
    window && window.innerWidth ? window.innerWidth : 1080
  );

  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dashboardModalImage } = useCustomer();

  // Functions
  const onBottomTextClick = () => {
    setWelcomePopup('');
    navigate(appRoute.DASHBOARD);
  };

  // Hooks
  useEffect(() => {
    if (window) {
      window.addEventListener('resize', () => {
        setScreenSize(window.innerWidth);
      });
    }
  }, []);

  useEffect(() => {
    if (screenSize < 1024) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [pathname, screenSize]);

  // useEffect(() => {
  //   console.log('dashboardModalImage----------', dashboardModalImage);
  // }, [dashboardModalImage]);

  return (
    <>
      {/* {screenSize && screenSize < 1024 ? ( */}
      <Modal
        // isOpen={welcomePopup === 'appDownloadAlert' || screenSize < 1024}
        isOpen={welcomePopup === 'appDownloadAlert' || isOpen}
        modalClass="app-alert"
        onLogout={onLogoutPress}
        title={screenSize < 576 ? t('ek_login_popup-app_h2') : t('ek_login_popup-app_h1')}
        isCloseButton={welcomePopup === 'appDownloadAlert' && screenSize >= 1024}
        onClose={() => setWelcomePopup('')}
        sm={screenSize > 576 && screenSize < 768}
        web={screenSize < 576}
        md={screenSize >= 768 && screenSize < 1024}
        isUserLoggedIn={isUserLoggedIn}>
        <div className="app-modal">
          {screenSize < 576 && (
            <p
              className="text-white"
              dangerouslySetInnerHTML={{ __html: t('ek_login_popup-app_text') }}
            />
          )}
          <div className="app-main-screen d-flex">
            <div className="app-main-img">
              <Image
                className="logo mx-100"
                refs="ek_web_popup_app_bg"
                resRefs="ek_web_popup_app_bg"
                alt="sim card activation "
                mobileStyle={{ height: '484', width: '180' }}
                screenSize={screenSize}
              />
            </div>
            <div>
              <ul className="nav d-flex flex-column">
                <li className="nav-item pt-2 mt-1">
                  <a href={t('ek_link_playstore')} target="_blank" rel="noreferrer">
                    <img src={AppStore} alt="appstore" />
                  </a>
                </li>
                <li className="nav-item pt-2 mt-1">
                  <a href={t('ek_link_appstore')} target="_blank" rel="noreferrer">
                    <img src={IosStore} alt="iosstore" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <p
            className="copytext pt-2 pb-0"
            dangerouslySetInnerHTML={{ __html: t('ek_login_popup-app_copy') }}
          />
        </div>
      </Modal>
      {/* ) : ( */}
      {/* <Modal
          isOpen={welcomePopup === 'appDownloadAlert'}
          isCloseButton
          modalClass="app-alert dashboard-modal"
          title={t('ek_login_popup_2_h1')}
          onClose={() => setWelcomePopup('')}
          onLogout={onBottomTextClick}
          isDashboardModal
          imageSource={dashboardModalImage?.media_url_display}
          screenSize={screenSize}
        >
          <div className="app-main-screen d-flex">
            <div
              className="text-center dashborad-modal-text"
              dangerouslySetInnerHTML={{ __html: t('ek_login_popup_2_text1') }}
            />
          </div>
          <Button
            buttonClass={appButtonType.SECONDARY}
            onClick={() => {
              setWelcomePopup('');
              navigate(appRoute.TARIFF_OPTION);
            }}
          >
            {t('ek_login_popup_2_button')}
          </Button>
        </Modal> */}
      {/* )} */}
    </>
  );
}

export default AppDownloadAlert;
