import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import { useAccount, useCustomer, useDocument } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import ArrowRightSmall from '@theme/assets/img/arrow_right_small.svg';
import Kontakt from '@theme/assets/img/kontakt.svg';
import Faq from '@theme/assets/img/faq.svg';
import ErklaerVideos from '@theme/assets/img/erklaervideos.svg';
import Rechtliches from '@theme/assets/img/rechtliches.svg';
import VertragKuendigen from '@theme/assets/img/vertrag_kuendigen.svg';
import Sperren from '@theme/assets/img/sperren.svg';

import './style.scss';

export function HelpAndServices() {
  // Constants

  const { serviceImgBg } = useDocument();

  // States

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isAccountLoading } = useAccount();

  // Functions

  // Hooks
  useEffect(() => {
    if (
      isStaticContentLoading ||
      isCustomerLoading ||
      isAutoTopUpLoading ||
      isDashboardLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isCustomerLoading,
    isAutoTopUpLoading,
    isDashboardLoading,
    isAccountLoading
  ]);

  return (
    <div
      className="advanatges-bg"
      style={{
        backgroundImage: `url(${
          serviceImgBg?.responsive_image ? serviceImgBg.responsive_image['2x'] : ''
        })`
      }}
    >
      <div className="container">
        <MetaTitle
          title={t('ek_help_and_service_meta_title')}
          description={t('ek_user_profile_meta_description')}
          keywords={t('ek_user_profile_meta_keywords')}
        />
        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_web_help_service_h') }} />
            <br />
          </div>
        </div>
        <div className="mt-4 pt-2">
          <div className="row">
            <div className="help-legal">
              <div className="col-lg-4">
                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.CONTACT);
                  }}
                >
                  <span>
                    <img src={Kontakt} alt="Kontakt-icon" />
                    <h5 className="copytext-medium">{t('ek_service_contact')}</h5>
                  </span>
                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.FAQ);
                  }}
                >
                  <span>
                    <img src={Faq} alt="rightarrow-icon" />
                    <h5 className="copytext-medium">{t('ek_service_faq')}</h5>
                  </span>
                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.EXPLAINER_VIDEO);
                  }}
                >
                  <span>
                    <img src={ErklaerVideos} alt="ErklaerVideos-icon" />
                    <h5 className="copytext-medium">{t('ek_service_video')}</h5>
                  </span>
                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.LEGAL);
                  }}
                >
                  <span>
                    <img src={Rechtliches} alt="rightarrow-icon" />
                    <h5 className="copytext-medium">{t('ek_service_legal')}</h5>
                  </span>
                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  // onClick={() => {
                  //   navigate(appRoute.CANCEL_CONTRACT);
                  // }}
                  onClick={() => window.open(t("ek_service_link"), "_blank")}
                >
                  <span>
                    <img src={VertragKuendigen} alt="VertragKuendigen-icon" />
                    <h5 className="copytext-medium">{t('ek_service_termination')}</h5>
                  </span>
                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.LOCK_UNLOCK_SIM);
                  }}
                >
                  <span>
                    <img src={Sperren} alt="Sperren-icon" />
                    <h5 className="text-danger copytext-medium mt-0">
                      {t('ek_service_block-sim')}
                    </h5>
                  </span>
                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpAndServices;

export * from './Contact';
export * from './Faq';
export * from './ExplainerVideo';
export * from './Legal';
export * from './CancelContract';
export * from './LockUnlockSIM';
