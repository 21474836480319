/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useMemo, useState } from 'react';
import axios from 'axios';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';

import {
  appAutoTopUpType,
  DEFAULT_FIELD_VALIDATION_TYPE,
  // getLowBalanceTopUp,
  getLowBalanceTopUpWithoutAmount,
  generateParamsForAddToCart,
  useAppConfig,
  getTopUpWithoutAmount,
  StatusCodes
} from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';
import {
  AutoTopUpFor,
  appAlert,
  appAutoTopUpPeriodType,
  appPaymentMethods,
  appPaymentProductType,
  appRegex,
  appRoute,
  // appRoute,
  appStorage,
  appTopUpTo,
  appTopUpType,
  formValidation,
  generateUUID,
  gettingNumberForGerman,
  inputValidation,
  validateDOB,
  validateDOBde,
  validateLowerCase,
  validateNumber,
  validateSpecial,
  validateStringLength,
  validateUpperCase
} from '@utils/globalConstant';
import { useAlert } from '@context/Utils/Alert';
import {
  DEFAULT_SALUTATION_DATA,
  DEFAULT_COUNTRIES_DATA,
  DEFAULT_IDENTITY_TYPES_DATA,
  DEFAULT_NATIONALITIES_DATA,
  DEFAULT_MPN_PROVIDER_DATA,
  DEFAULT_PROVIDERS_DATA
} from '@utils/dafaultLookups';
import { useNavigate } from 'react-router-dom';
// import { useCustomer } from '../Customer';

export const ActivationContext = createContext({});

export function ActivationContextProvider({ children, config: { storage } }) {
  // States
  // API Data Storage States
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentProductLoading, setIsPaymentProductLoading] = useState(false);
  const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(false);

  const [ip, setIp] = useState('');

  const [bookableTariffs, setBookableTariffs] = useState([]);
  const [selectedTariffId, setSelectedTariffId] = useState(0);
  const [paymentProducts, setPaymentProducts] = useState([]);
  const [allPaymentProducts, setAllPaymentProducts] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [simProvider, setSimProvider] = useState([]);
  const [areaCode, setAreaCode] = useState([]);
  const [salutations, setSalutations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [identityTypes, setIdentityTypes] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [isChargingClicked, setIsChargingClicked] = useState(true);
  const [isSimInvalid, setIsSimInvalid] = useState(false);
  const [activateSimSuccessPopup, setActivateSimSuccessPopup] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [isActivationClicked, setIsActivationClicked] = useState(false);
  const [emailUniqueId, setEmailUniqueId] = useState('');
  const [emailCodeError, setEmailCodeError] = useState(false);
  const [isAutoLowEnabled, setIsAutoLowEnabled] = useState(false);

  // Special data for some other unique inputs
  const [otherAmount, setOtherAmount] = useState(''); // Input for Direct topup
  // To show address after product selection in payment activation
  const [showAddress, setShowAddress] = useState(false);
  // TODO: Store data in storage for verification once callback / returns.
  const [cartName, setCartName] = useState(null); // Cart ID / Name when added to card.
  const [paymentAuthData, setPaymentAuthData] = useState({});
  const [orderNumber, setOrderNumber] = useState(null); // order ID / Name when processed with payment.
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [postIdentUrl, setPostIdentUrl] = useState(null);
  const [autoTopupMoreInfo, setAutoTopupMoreInfo] = useState(false);
  const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState('');

  // Context
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const { env } = useAppConfig();
  const { t, staticContentData } = useStaticContent();
  // const {ek_ap_personal_first-name_input_error-msg} = t();

  const {
    onValidateSim,
    oncountriesCall,
    onIdentityTpesCall,
    onsalutationCall,
    onActivateSim,
    onNationalitiesCall,
    onAreaCode,
    onSimProvider,
    onTwoFactorAuthPinCall,
    onTwoFactorAuthVerificationCall
    // onChangePasswordCall
  } = useMobileOne();
  const {
    onSsoPaymentToken,
    onPaymentProduct,
    onPaymentAddToCart,
    onPaymentMethod,
    onPaymentCheckoutAddress,
    onCheckoutPaymentStart
  } = useAlphaComm();
  // const { getCustomerData } = useCustomer();

  // Step 1 - Phone Number Initial Values & Validations
  const phoneNumberInitialValue = {
    msisdn: '',
    iccid: '',
    puk: '',
    mnp: false,
    email: '',
    emailCode: '',
    currentProvider: '',
    type: '1',
    oldNumber: '',
    oldNumberPrefix: ''
  };

  const phoneNumberValidations = Yup.object().shape({
    msisdn: formValidation({
      regex: /^\d{10,12}$/,
      validErrorMessage: t('ek_ap_activation_nr_input_error-msg')
    }),
    puk: formValidation({
      required: t('ek_ap_activation_puk_input_error-msg'),
      regex: /^\d{4,8}$/,
      validErrorMessage: t('ek_ap_activation_puk_input_error-msg')
    })
  });
  const phoneNumberValidationsWithMNP = Yup.object().shape({
    // msisdn: formValidation({
    //   regex: /^\d{10,12}$/,
    //   validErrorMessage: t('ek_ap_activation_nr_input_error-msg')
    // }),
    // iccid: formValidation({
    //   regex: /^\d{5,20}$/,
    //   validErrorMessage: t('ek_ap_activation_nr_input_error-msg')
    // }),
    // puk: formValidation({
    //   required: t('ek_ap_activation_puk_input_error-msg'),
    //   regex: /^\d{4,8}$/,
    //   validErrorMessage: t('ek_ap_activation_puk_input_error-msg')
    // }),
    currentProvider: Yup.string().required(t('ek_ap_nr-portability_input2_error-msg')),
    // oldNumberPrefix: Yup.string().required(t('ek_ap_nr-portability_input1_error-msg')),
    oldNumberPrefix: formValidation({
      required: t('ek_ap_nr-portability_input2_error-msg'),
      regex: /^\d{4}$/,
      validErrorMessage: t('ek_ap_nr-portability_input2_error-msg')
    }),
    oldNumber: formValidation({
      required: t('ek_ap_activation_nr_input_error-msg'),
      regex: /^\d{1,10}$/,
      validErrorMessage: t('ek_ap_nr-portability_input3_error-msg')
    })
  });
  const PhoneNumberCustomValidation = (values) => {
    // eslint-disable-next-line prefer-const
    let errors = {};
    if (!values.msisdn && !values.iccid) {
      errors.msisdn = t('ek_ap_activation_nr_input_error-msg');
      // errors.msisdn = 'please_msisdn_or_iccid_required';
      errors.iccid = t('ek_ap_activation_nr_input_error-msg');
      // errors.iccid = 'please_iccid_or_msisdn_required';
    }

    if (values.msisdn && !values.iccid && !/^\d{5,20}$/.test(values.msisdn)) {
      errors.msisdn = t('ek_ap_activation_nr_input_error-msg');
    }

    if (values.iccid && !values.msisdn && !/^\d{5,20}$/.test(values.iccid)) {
      errors.iccid = t('ek_ap_activation_nr_input_error-msg');
    }

    if (values.msisdn && values.iccid) {
      errors.msisdn = t('ek_ap_activation_nr_input_error-msg');
      // errors.msisdn = 'please_enter_any_one_of_msisdn_or_iccid';
      errors.iccid = t('ek_ap_activation_nr_input_error-msg');
      // errors.iccid = 'please_enter_any_one_of_iccid_or_msisdn';
    }

    return errors;
  };

  const validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required(t('ek_ap_personal_email1_input_error-msg'))
      .email(t('ek_ap_personal_email1_input_error-msg'))
  });

  const staticTariffManipulation = (tariffApiData) => {
    const staticApiData = staticContentData;

    if (staticApiData != null) {
      if (staticApiData.tariff != null && staticApiData.tariff.length > 0) {
        let staticContentTariff = staticApiData.tariff;
        let apiTariffData = tariffApiData;
        let filterArray = [];

        filterArray = staticContentTariff.filter((staticContentTariffItem) =>
          apiTariffData.some(
            (apiTariffDataitem) => staticContentTariffItem.id === apiTariffDataitem.id
          )
        );

        // eslint-disable-next-line prefer-arrow-callback
        filterArray.sort(function (x, y) {
          return x.sortOrder - y.sortOrder;
        });

        return filterArray;
      }
      return tariffApiData;
    }
    return tariffApiData;
  };

  const validationSchemaCode = Yup.object().shape({
    emailCode: Yup.number()
      .typeError(t('ek_ap_email_err_wrong_code'))
      .required(t('ek_ap_email_err_no_code'))
      .test('code', t('ek_ap_email_err_wrong_code'), (hp) => /^\d{4}$/.test(hp))
  });

  const [phoneNumberActivationForm, setPhoneNumberActivationForm] =
    useState(phoneNumberInitialValue);

  // Step 2 - Tariff Activation Initial Values & Validations
  const tariffActivationInitialValue = {
    chosenTariffId: 0,
    chosenTariffName: '',
    tariffColor: ''
  };
  const tariffActivationValidation = Yup.object().shape({
    chosenTariffId: formValidation({
      type: DEFAULT_FIELD_VALIDATION_TYPE.NUMBER,
      required: 'required_field'
    })
  });
  const [tariffActivationForm, setTariffActivationForm] = useState(tariffActivationInitialValue);

  // Step 3 - Personal Data Initial Values & Validations
  const personalDataInitialValue = {
    // salutation: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    houseNumber: '',
    street: '',
    zip: '',
    city: '',
    countryCode: '',
    emailAddress: '',
    // alternateNumberPrefix: '',
    // alternateNumber: '',
    legitimationMethod: '3',
    hotlinePassword: '',
    confirmHotlinePassword: '',
    title: '7',
    additionalInfo: 'additionalInfo'
  };

  const personalDataValidations = Yup.object().shape({
    // salutation: Yup.string().required(t('ek_ap_personal_h1_error-msg')),
    firstName: formValidation({
      required: `${t('ek_ap_personal_first-name_input_error-msg')}`,
      regex: /^[A-Za-zÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿœŒ-\s]{2,25}$/,
      validErrorMessage: t('ek_ap_personal_first-name_input_error-msg')
    }),
    lastName: formValidation({
      required: t('ek_ap_personal_last-name_input_error-msg'),
      regex: /^[A-Za-zÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿœŒ-\s]{2,25}$/,
      validErrorMessage: t('ek_ap_personal_last-name_input_error-msg')
    }),
    // birthDate: Yup.date()
    //   .required(t('ek_ap_personal_birth-date_input_error-msg'))
    //   .max(validateDOB(), t('ek_ap_personal_birth-date_input_error-msg')),
    birthDate: Yup  
    .date()
    .transform((value, originalValue) => {
      // Parse the date using Moment.js
      const parsedDate = moment(originalValue, 'DD.MM.YYYY', true); // strict parsing
      // If the date is valid and the day and month match the original values, return the date object
      // Otherwise, return an `InvalidDate`
      if (parsedDate.isValid()) {
        const [day, month, year] = originalValue.split('.');
        if (
          parsedDate.date() === parseInt(day, 10) &&
          parsedDate.month() + 1 === parseInt(month, 10)
        ) {
           return parsedDate.toDate();
        }
      }
      return new Date(NaN); // Invalid date
    })
    .max(validateDOBde(), t('ek_ap_personal_birth-date_input_error-msg'))
    .required(t('ek_ap_personal_birth-date_input_error-msg2'))
    .typeError(t('ek_ap_personal_birth-date_input_error-msg3')),
    houseNumber: formValidation({
      required: t('ek_ap_personal_house-no_input_error-msg'),
      regex: /^[A-Za-z0-9ÄÖÜßäöü.-]{0,25}$/,
      validErrorMessage: t('ek_ap_personal_house-no_input_error-msg')
    }),
    street: formValidation({
      required: t('ek_ap_personal_street_input_error-msg'),
      regex: /^.{0,100}$/,
      validErrorMessage: t('ek_ap_personal_street_input_error-msg')
    }),
    zip: formValidation({
      required: t('ek_ap_personal_zip_input_error-msg'),
      regex: /^[0-9]{0,25}$/,
      validErrorMessage: t('ek_ap_personal_zip_input_error-msg')
    }),
    city: formValidation({
      required: t('ek_ap_personal_town_input_error-msg'),
      regex:
        /^[a-zA-Z0-9-`ºªµ¼½¿ÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿœŒ´¡]{0,75}$/,
      validErrorMessage: t('ek_ap_personal_town_input_error-msg')
    }),
    countryCode: formValidation({
      required: t('ek_ap_personal_country_input_error-msg'),
      regex:
        /^[a-zA-Z0-9-`ºªµ¼½¿ÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿœŒ´¡]{1,11}$/,
      validErrorMessage: t('ek_ap_personal_country_input_error-msg')
    }),
    // emailAddress: formValidation({
    //   required: t('ek_ap_personal_email1_input_error-msg'),
    //   regex:
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //   validErrorMessage: t('ek_ap_personal_email1_input_error-msg')
    // }),
    // alternateNumberPrefix: formValidation({}).when('alternateNumber', {
    //   is: (value) => value,
    //   then: Yup.string()
    //     .matches(/^\d{1,6}$/, t('ek_ap_nr-portability_input1_error-msg'))
    //     .required(t('ek_ap_nr-portability_input1_error-msg'))
    // }),
    // alternateNumber: formValidation({}).when('alternateNumberPrefix', {
    //   is: (value) => value,
    //   then: Yup.string()
    //     .matches(/^\d{1,10}$/, t('ek_ap_personal_nr_input_error-msg'))
    //     .required(t('ek_ap_personal_nr_input_error-msg'))
    // }),
    // identityType: Yup.number().required(t('ek_ap_personal_id_input_error-msg')),
    // identityNumber: formValidation({
    //   required: t('ek_ap_personal_id-nr_input_error-msg'),
    //   regex: /^[a-zA-Z0-9(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D]{1,20}$/,
    //   validErrorMessage: t('ek_ap_personal_id-nr_input_error-msg')
    // }),
    // nationality: Yup.number().required(t('ek_ap_personal_nationality_input_error-msg')),
    // placeOfIssue: formValidation({
    //   required: t('ek_ap_personal_authority_error-msg'),
    //   regex: /^[a-zA-Z(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D/;,.]{1,40}$/,
    //   validErrorMessage: t('ek_ap_personal_authority_error-msg')
    // }),
    // validUntil: Yup.date()
    //   .required(t('ek_ap_personal_validation-date_error-msg'))
    //   .min(new Date(), t('ek_ap_personal_validation-date_error-msg')),
    hotlinePassword: formValidation({
      required: t('ek_ap_personal_holine-pw_input1_error-msg'),
      regex: /^[(A-Z)|(Ö)|(Ä)|(Ü)|(a-z)|(0-9)|(ü)|(ö)|(ä)|(ß)]{4,20}$/,
      validErrorMessage: t('ek_ap_personal_holine-pw_input1_error-msg')
    }),
    confirmHotlinePassword: Yup.string()
      .required(`${t('ek_ap_personal_holine-pw_input2_error-msg')}`)
      .oneOf([Yup.ref('hotlinePassword')], t('ek_ap_personal_holine-pw_input2_error-msg'))
  });

  const [personalDataForm, setPersonalDataForm] = useState(personalDataInitialValue);

  // Step 4 - Login Password Initial Values & Validations
  const loginPasswordInitialValue = {
    cscPassword: '',
    confirmCscPassword: ''
  };
  const loginPasswordValidation = Yup.object().shape({
    cscPassword: formValidation({
      required: t('ek_ap_password_pw1_error-msg'),
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{12,20}$/,
      validErrorMessage: t('ek_ap_password_pw1_error-msg')
    }),
    confirmCscPassword: Yup.string()
      .oneOf([Yup.ref('cscPassword')], t('ek_ap_password_pw2_error-msg'))
      .required(t('ek_ap_password_pw2_error-msg'))
  });
  const [loginPasswordForm, setLoginPasswordForm] = useState(loginPasswordInitialValue);

  // Password Validation
  // const validatePasswordWithKeys = (values, firstPasswordKey, confirmPasswordKey) => {
  //   // string, controlName, password

  //   const errors = Object.keys(values).map((controlName) => {
  //     const string = values[controlName];
  //     if (!string) {
  //       return {
  //         [controlName]: t('ek_ap_password_pw1_error-msg')
  //       };
  //     }

  //     const hasLength = validateStringLength(values[firstPasswordKey], 12, 20);
  //     const hasUpperCase = validateUpperCase(values[firstPasswordKey]);
  //     const hasLowerCase = validateLowerCase(values[firstPasswordKey]);
  //     const hasNumber = validateNumber(values[firstPasswordKey]);
  //     const hasSpecial = validateSpecial(values[firstPasswordKey]);

  //     if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
  //       return {
  //         otherErrors: {
  //           [inputValidation.MIN_MAX_ERROR]: hasLength,
  //           [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //           [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //           [inputValidation.NUMBER_ERROR]: hasNumber,
  //           [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //           [inputValidation.MATCH]: values[firstPasswordKey] === values[confirmPasswordKey]
  //         }
  //       };
  //     }

  //     if (
  //       (values[firstPasswordKey] || values[confirmPasswordKey]) &&
  //       values[firstPasswordKey] !== values[confirmPasswordKey]
  //     ) {
  //       return { [confirmPasswordKey]: t('ek_ap_password_pw1_error-msg') };
  //     }

  //     return {};
  //   });
  //   return Object.assign(...errors);
  // };

  const validatePwd = (password) => {
    const pattern =
      t('ek_password_regex') !== 'ek_password_regex'
        ? new RegExp(`${t('ek_password_regex')}`)
        : appRegex.validatePwd;
    return pattern.test(password);
  };

  const validatePwdStringLength = (password) => {
    const pattern =
      t('ek_password_string_length_regex') !== 'ek_password_string_length_regex'
        ? new RegExp(`${t('ek_password_string_length_regex')}`)
        : appRegex.validatePwdStringLength;
    return pattern.test(password);
  };
  const validatePwdUpperOrLowerCase = (password) => {
    const pattern =
      t('ek_password_upper_or_lower_case_regex') !== 'ek_password_upper_or_lower_case_regex'
        ? new RegExp(`${t('ek_password_upper_or_lower_case_regex')}`)
        : appRegex.validatePwdUpperOrLowerCase;
    return pattern.test(password);
  };
  const validatePwdNumbers = (password) => {
    const pattern =
      t('ek_password_numbers_regex') !== 'ek_password_numbers_regex'
        ? new RegExp(`${t('ek_password_numbers_regex')}`)
        : appRegex.validatePwdNumbers;
    return pattern.test(password);
  };
  const validatePwdSpecialChar = (password) => {
    const pattern =
      t('ek_password_special_chars_regex') !== 'ek_password_special_chars_regex'
        ? new RegExp(`${t('ek_password_special_chars_regex')}`)
        : appRegex.validatePwdSpecialChar;
    return pattern.test(password);
  };

  // Password Validation
  const validatePasswordWithKeys = (values, firstPasswordKey, confirmPasswordKey) => {
    // string, controlName, password
    setChangePasswordErrorMsg('');
    if (!values[firstPasswordKey] && !values[confirmPasswordKey]) return {};

    const errors = Object.keys(values).map((controlName) => {
      const string = values[controlName];
      if (!string) {
        return {
          [controlName]: t('ek_ap_password_pw1_error-msg') // t('ek_ap_password_pw1_error-msg')
        };
      }

      // const hasLength = validateStringLength(values[firstPasswordKey], 12, 20);
      // const hasUpperCase = validateUpperCase(values[firstPasswordKey]);
      // const hasLowerCase = validateLowerCase(values[firstPasswordKey]);
      // const hasNumber = validateNumber(values[firstPasswordKey]);
      // const hasSpecial = validateSpecial(values[firstPasswordKey]);
      // const hasVerified = !validatePwd(values[firstPasswordKey]);
      const hasLength = !validatePwdStringLength(values[firstPasswordKey]);
      const hasUpperCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasLowerCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasNumber = !validatePwdNumbers(values[firstPasswordKey]);
      const hasSpecial = !validatePwdSpecialChar(values[firstPasswordKey]);

      let errorMsg = {};

      if (
        // hasVerified ||
        hasLength ||
        hasUpperCase ||
        hasLowerCase ||
        hasNumber ||
        hasSpecial ||
        values[firstPasswordKey] !== values[confirmPasswordKey]
      ) {
        errorMsg = {
          otherErrors: {
            // [inputValidation.INVALID]: hasVerified,
            [inputValidation.MIN_MAX_ERROR]: hasLength,
            [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
            [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
            [inputValidation.NUMBER_ERROR]: hasNumber,
            [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
            [inputValidation.MATCH]: values[firstPasswordKey] !== values[confirmPasswordKey]
          }
        };
      }

      if (
        values[firstPasswordKey] &&
        values[confirmPasswordKey] &&
        values[firstPasswordKey] !== values[confirmPasswordKey]
      ) {
        errorMsg = { ...errorMsg, [confirmPasswordKey]: t('ek_ap_password_pw1_error-msg') };
      }

      return errorMsg;
    });
    return Object.assign(...errors);
  };

  // Step 5 - Overview Initial Values & Validations
  const overviewInitialValue = {
    voucher: '',
    termsAndConditions: false,
    thirdParty: false,
    acceptedCreditCheck: false,
    brandPartnerCustomMarketing: false,
    employee: false,
    marketingMultibrand: false
  };
  const overviewValidation = Yup.object().shape({
    msisdn: formValidation({
      required: t('ek_ap_activation_nr_input_error-msg'),
      regex: /^\d{10,12}$/,
      validErrorMessage: t('ek_ap_activation_nr_input_error-msg')
    }),
    // NOTE: as edit button is deactivated in overview in activation journey,
    // this validation is not required
    // NOTE: if edit button is activated in overview, this validation needs to be refactored,
    // because it is not the correct one as it is necessary from Password CR on (July 2024)
    // cscPassword: formValidation({
    //   required: t('ek_ap_password_pw1_error-msg'),
    // NOTE: OLD INCORRECT PASSWORD REGEX --> please have a look in function 'validatePasswordWithKeys'
    //   regex: 
    //   validErrorMessage: t('ek_ap_password_pw1_error-msg')
    // }),
    // NOTE: as edit button is deactivated in overview in activation journey
    // hotlinePassword: formValidation({
    //   required: t('ek_ap_personal_holine-pw_input1_error-msg'),
    //   regex: /^[(A-Z)|(Ö)|(Ä)|(Ü)|(a-z)|(0-9)|(ü)|(ö)|(ä)|(ß)]{4,20}$/,
    //   validErrorMessage: t('ek_ap_personal_holine-pw_input1_error-msg')
    // }),  
    countryCode: formValidation({
      required: t('ek_ap_personal_country_input_error-msg'),
      regex:
        /^[a-zA-Z0-9-`ºªµ¼½¿ÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿœŒ´¡]{1,11}$/,
      validErrorMessage: t('ek_ap_personal_country_input_error-msg')
    }),
    // salutation: Yup.string().required(t('ek_ap_personal_h1_error-msg')),
    firstName: formValidation({
      required: t('ek_ap_personal_first-name_input_error-msg'),
      regex: /^[A-Za-zÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿœŒ-\s]{2,25}$/,
      validErrorMessage: t('ek_ap_personal_first-name_input_error-msg')
    }),
    lastName: formValidation({
      required: t('ek_ap_personal_last-name_input_error-msg'),
      regex: /^[A-Za-zÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿœŒ-\s]{2,25}$/,
      validErrorMessage: t('ek_ap_personal_last-name_input_error-msg')
    }),
    // birthDate: Yup.date()
    //   .required(t('ek_ap_personal_birth-date_input_error-msg'))
    //   .max(validateDOB(), t('ek_ap_personal_birth-date_input_error-msg')),
    birthDate: Yup.date()
      .transform((value, originalValue) => {
        // Parse the date using Moment.js
        const parsedDate = moment(originalValue, 'DD.MM.YYYY', true); // strict parsing
        // If the date is valid and the day and month match the original values, return the date object
        // Otherwise, return an `InvalidDate`
        if (parsedDate.isValid()) {
          const [day, month, year] = originalValue.split('.');
          if (
            parsedDate.date() === parseInt(day, 10) &&
            parsedDate.month() + 1 === parseInt(month, 10)
          ) {
            return parsedDate.toDate();
          }
        }
        return new Date(NaN); // Invalid date
      })
      .max(validateDOBde(), t('ek_ap_personal_birth-date_input_error-msg'))
      .required(t('ek_ap_personal_birth-date_input_error-msg2'))
      .typeError(t('ek_ap_personal_birth-date_input_error-msg3')),
    houseNumber: formValidation({
      required: t('ek_ap_personal_house-no_input_error-msg'),
      regex: /^[A-Za-z0-9ÄÖÜßäöü.-]{0,25}$/,
      validErrorMessage: t('ek_ap_personal_house-no_input_error-msg')
    }),
    street: formValidation({
      required: t('ek_ap_personal_street_input_error-msg'),
      regex: /^.{0,100}$/,
      validErrorMessage: t('ek_ap_personal_street_input_error-msg')
    }),
    emailAddress: formValidation({
      required: t('ek_ap_personal_email1_input_error-msg'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('ek_ap_personal_email1_input_error-msg')
    }),
    zip: formValidation({
      required: t('ek_ap_personal_zip_input_error-msg'),
      regex: /^[0-9]{0,25}$/,
      validErrorMessage: t('ek_ap_personal_zip_input_error-msg')
    }),
    city: formValidation({
      required: t('ek_ap_personal_town_input_error-msg'),
      regex:
        /^[a-zA-Z0-9-`ºªµ¼½¿ÀÁÂÃÄÅÆÇÈÉÊÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿœŒ´¡]{0,75}$/,
      validErrorMessage: t('ek_ap_personal_town_input_error-msg')
    }),
    // identityType: Yup.number().required(t('ek_ap_personal_id_input_error-msg')),
    // identityNumber: formValidation({
    //   required: t('ek_ap_personal_id-nr_input_error-msg'),
    //   regex: /^[a-zA-Z0-9(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D]{1,20}$/,
    //   validErrorMessage: t('ek_ap_personal_id-nr_input_error-msg')
    // }),
    // nationality: Yup.number().required(t('ek_ap_personal_nationality_input_error-msg')),
    // placeOfIssue: formValidation({
    //   required: t('ek_ap_personal_authority_error-msg'),
    //   regex: /^[a-zA-Z(Ö)(Ä)(Ü)(ü)(ö)(ä)(ß)\s\x2D/;,.]{1,40}$/,
    //   validErrorMessage: t('ek_ap_personal_authority_error-msg')
    // }),
    // validUntil: Yup.date()
    //   .required(t('ek_ap_personal_validation-date_error-msg'))
    //   .min(new Date(), t('ek_ap_personal_validation-date_error-msg')),
    termsAndConditions: Yup.boolean().isTrue(t('ek_ap_overview_text1_error-msg')),
    thirdParty: Yup.boolean(t('ek_ap_overview_text2_error-msg'))
  });
  const [overviewForm, setOverviewForm] = useState(overviewInitialValue);

  // Step 6 - Part A (Select Product & Process To Payment) Initial Values & Validations

  const processToPaymentInitialValue = {
    isDirectProduct: 'NO',
    type: appTopUpType.AUTOMATIC,
    chargeTo: appTopUpTo.SELF,

    productId: '',
    productType: '',
    productAmount: '',
    paymentMethodName: '', // appPaymentMethods
    terms: false
  };

  const processToPaymentValidations = Yup.lazy((values) => {
    if (values.periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
      return Yup.object().shape({
        periodDay: formValidation({ required: t('ek_ap_topup_amount_error-msg') }),
        productId: formValidation({ required: t('ek_ap_select_product_error-msg') })
      });
    }
    if (values.periodType === appAutoTopUpPeriodType.LOW_BALANCE) {
      return Yup.object().shape({
        periodAmount: formValidation({ required: t('ek_ap_topup_day_error-msg') }),
        productId: formValidation({ required: t('ek_ap_select_product_error-msg') })
      });
    }
    return Yup.object().shape({
      productId: formValidation({ required: t('ek_ap_select_product_error-msg') })
    });
  });

  const processToProductValidations = Yup.object().shape({
    periodType: formValidation({
      required: t('please_select_option_to_continue')
    }),
    // certainAmount: formValidation({}).when('periodType', {
    //   is: (value) => value === appAutoTopUpPeriodType.LOW_BALANCE,
    //   then: formValidation({
    //     required: t('ek_autotop-up_setup_option1')
    //   })
    // }),
    periodDay: formValidation({}).when('periodType', {
      is: (value) => value === appAutoTopUpPeriodType.ONCE_PER_MONTH,
      then: formValidation({
        required: t('ek_autotop-up_setup_option2')
      })
    })
  });

  const productValidation = Yup.lazy((values) => {
    const validationSchema = {
      productId: formValidation({ required: t('ek_reg_step6_item_err') }),
      paymentMethodName: formValidation({ required: t('ek_reg_step6_method_err') }),
      terms: formValidation({
        type: DEFAULT_FIELD_VALIDATION_TYPE.BOOLEAN,
        required: t('ek_reg_step6_terms_err')
      })
    };
    return Yup.object().shape(validationSchema);
  });

  const [processToPaymentForm, setProcessToPaymentForm] = useState(processToPaymentInitialValue);
  // Step 6 - Part B (Select Payment Method & Process Order) Initial Values & Validations
  const chargingInitialValue = {
    paymentMethodName: ''
  };
  const chargingValidations = Yup.object().shape({
    paymentMethodName: formValidation({
      required: 'please_select_paymentMethod',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    })
  });
  const [chargingForm, setChargingForm] = useState(chargingInitialValue);
  const [emailModal, setEmailModal] = useState(false);

  // Data Fetch Functions

  // Stepper Functions
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  // Step 1 On Phone Number Form Submit
  const phoneNumberSubmit = async (values) => {
    setIsLoading(true);
    // showLoader();
    try {
      const params = { msisdn: values.msisdn, puk: values.puk };
      const { msisdn, puk } = values;

      const {
        status,
        data: { bookableTariffs: userBookableTariffs = [] }
      } = await onValidateSim(params);

      if (status === 200) {
        setPhoneNumberActivationForm({ ...phoneNumberActivationForm, msisdn, puk });
        setBookableTariffs(staticTariffManipulation(userBookableTariffs));
      } else {
        // setIsSimInvalid(true);
        throw new Error('Invalid');
      }
      setIsLoading(false);
      // hideLoader();
      return true;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t('ek_web_login_error-msg') });
      } else {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      // setIsSimInvalid(true);
      return false;
    }
  };

  const verifyEmail = async (values) => {
    try {
      setIsLoading(true);
      // showLoader();
      const uniqueIdEmail = generateUUID();
      setEmailUniqueId(uniqueIdEmail);
      const { data, success } = await onTwoFactorAuthPinCall({
        email: values.email,
        uniqueId: uniqueIdEmail
      });
      if (data && success) {
        setEmailModal(true);
        setPhoneNumberActivationForm({ ...phoneNumberActivationForm, email: values.email });
        setTimeout(() => {
          setEmailModal(false);
        }, 4000);
      }
      setIsLoading(false);
      // hideLoader();
      return data;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      console.log(error);
      return false;
    }
  };

  const verifyEmailCode = async (values) => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data, success } = await onTwoFactorAuthVerificationCall({
        pin: Number(values.emailCode),
        uniqueId: emailUniqueId
      });
      if (data && success) {
        setPhoneNumberActivationForm({ ...phoneNumberActivationForm, emailCode: values.emailCode });
        setPersonalDataForm({ ...personalDataForm, emailAddress: phoneNumberActivationForm.email });
      }
      setIsLoading(false);
      // hideLoader();
      return true;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        setEmailCodeError(true);
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      console.log(error);
      return false;
    }
  };

  // Resend otp code
  const onResendCode = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      const resendUniqueId = generateUUID();
      setEmailUniqueId(resendUniqueId);
      const { data, success } = await onTwoFactorAuthPinCall({
        email: phoneNumberActivationForm.email,
        uniqueId: resendUniqueId
      });
      if (data && success) {
        setIsLoading(false);
        // hideLoader();
        setEmailModal(true);
        setTimeout(() => {
          setEmailModal(false);
        }, 4000);
      }
      return true;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      console.log(error);
      return false;
    }
  };

  const phoneNumberActivationFormSubmit = (values) => {
    // Validate sim & get tariff & got to next step;
    try {
      const { mnp, currentProvider, oldNumber, oldNumberPrefix } = values;
      setIsLoading(true);
      // showLoader();
      if (mnp) {
        setPhoneNumberActivationForm({
          ...phoneNumberActivationForm,
          mnp,
          currentProvider,
          oldNumber,
          oldNumberPrefix
        });
      }
      nextStep();
      setIsLoading(false);
      // hideLoader();
      return true;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log(error);
      // setIsSimInvalid(true);
      // showAlert({
      //   type: appAlert.ERROR,
      //   message:
      //     error.error[0].messageBody === undefined
      //       ? 'Error in request or invalid rufnummer/already activated'
      //       : `Error in request. ${error.error[0].messageBody}`
      // });
      return false;
    }
  };
  const getIp = async () => {
    const ipResponse = await axios.get(`${env.REACT_APP_SERVER}api/ip/address`);
    console.log({ ipResponse });
    if (ipResponse) {
      setIp(ipResponse.data.ip);
    }
  };

  const getCountries = async () => {
    try {
      const { data = [] } = await oncountriesCall();
      if (data && data.length > 0) {
        setCountries(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, countryCode: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      setCountries(DEFAULT_COUNTRIES_DATA);
      return false;
    }
  };
  const getIdentityTypes = async () => {
    try {
      const { data = [] } = await onIdentityTpesCall();
      if (data && data.length > 0) {
        setIdentityTypes(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, identityType: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      setIdentityTypes(DEFAULT_IDENTITY_TYPES_DATA);
      return false;
    }
  };
  const getSalutation = async () => {
    try {
      const { data = [], success = false } = await onsalutationCall();
      if (data && data.length > 0 && success) {
        setSalutations(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, salutation: findFirst.id });
          return setTimeout(() => data, 10000);
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      setSalutations(DEFAULT_SALUTATION_DATA);
      return false;
    }
  };
  const getNationality = async () => {
    try {
      const { data = [] } = await onNationalitiesCall();
      if (data && data.length > 0) {
        setNationality(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, nationality: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      setNationality(DEFAULT_NATIONALITIES_DATA);
      return false;
    }
  };
  const getAreaCode = async () => {
    try {
      const { data = [] } = await onAreaCode();
      if (data && data.length > 0) {
        setAreaCode(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, oldNumberPrefix: findFirst.id });
        }
      }
      return data;
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      setAreaCode(DEFAULT_MPN_PROVIDER_DATA);
      return false;
    }
  };
  const getSimProvider = async () => {
    try {
      const { data = {} } = await onSimProvider();
      if (data && data.length > 0) {
        setSimProvider(data);
        const findFirst = data.find((a) => a.id);
        if (findFirst && findFirst.id) {
          setPersonalDataForm({ ...personalDataForm, currentProvider: findFirst.id });
          return data;
        }
        return data;
      }
      return data;
    } catch (error) {
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      setSimProvider(DEFAULT_PROVIDERS_DATA);
      return false;
    }
  };
  const getLookup = async () => {
    // await getSalutation();
    setIsLoading(true);
    getSalutation();
    getCountries();
    // getIdentityTypes();
    // getNationality();
    getAreaCode();
    getSimProvider();
    setIsLoading(false);
  };

  const onTopupError = async () => {
    try {
      setIsLoading(true);
      const activationMsisdn = await storage.encryptedGetItem(appStorage.ACTIVATION_MSISDN);
      const ssoData = await storage.encryptedGetItem(appStorage.ACTIVATION_DATA);
      const activationPersonalData = await storage.encryptedGetItem(
        appStorage.ACTIVATION_PERSONAL_DATA
      );
      const personalData = JSON.parse(activationPersonalData);
      // console.log('activationData', personalData);
      const { clientId, alphacommHash, signupId } = JSON.parse(ssoData);
      setPersonalDataForm(personalData);
      setPhoneNumberActivationForm({ ...phoneNumberActivationForm, msisdn: activationMsisdn });
      setCurrentStep(6);
      navigate(appRoute.ACTIVATION);

      await ssoPaymentToken({
        clientId: signupId || clientId,
        alphacommHash,
        msisdn: activationMsisdn
      });
      setIsLoading(false);
    } catch (error) {
      console.log('', error);
      setIsLoading(false);
    }
  };
  // useEffect(() => {
  //   if (personalDataForm.salutation && countries.length === 0) getCountries();
  // }, [personalDataForm.salutation]);
  // useEffect(() => {
  //   if (personalDataForm.countryCode && identityTypes.length === 0) getIdentityTypes();
  // }, [personalDataForm.countryCode]);
  // useEffect(() => {
  //   if (personalDataForm.identityType && nationality.length === 0) getNationality();
  // }, [personalDataForm.identityType]);
  // useEffect(() => {
  //   if (personalDataForm.nationality && areaCode.length === 0) getAreaCode();
  // }, [personalDataForm.nationality]);
  // useEffect(() => {
  //   if (personalDataForm.oldNumberPrefix && simProvider.length === 0) getSimProvider();
  // }, [personalDataForm.oldNumberPrefix]);

  // Step 2 On Tariff Form Submit
  const tariffActivationFormSubmit = (values) => {
    setIsLoading(true);
    setTariffActivationForm(values);
    setIsLoading(false);
    nextStep();
  };

  // Step 3 On Personal Data Form Submit
  const personalDataFormSubmit = (values) => {
    try {
      // Fill Personal Data & Go to Next Step;
      setIsLoading(true);

      //  TODO
      const updatedDate = moment(values.birthDate, 'DD.MM.YYYY').format('DD.MM.YYYY');
      const updatedValues = { ...values, birthDate: updatedDate };
      // setPersonalDataForm(values);
      setPersonalDataForm(updatedValues);
      setIsLoading(false);
      nextStep();
      // return values;
      return updatedValues;

    } catch (error) {
      setIsLoading(false);
      console.log('Submit Error', error);
      return false;
    }
  };

  // Step 4 On Login Password Form Submit
  const loginPasswordFormSubmit = (values) => {

    if (!validatePwd(values.cscPassword)) {
      setChangePasswordErrorMsg(t('ek_password_error_invalid-char'));
      return false;
    }

    setIsLoading(true);
    setPersonalDataForm({
      ...personalDataForm,
      // msisdn: phoneNumberActivationForm.msisdn,
      cscPassword: values.cscPassword
    });
    setOverviewForm({
      ...overviewForm,
      msisdn: personalDataForm.msisdn,
      chosenTariffId: tariffActivationForm.chosenTariffId
    });
    setIsLoading(false);
    nextStep();
  };

  // Step 5 On Overview Form Submit
  // const getPaymentProducts = async () => {
  //   try {
  //     const {
  //       data: { items = [] }
  //     } = await onPaymentProduct();

  //     const { periodType } = processToPaymentForm;
  //     if (
  //       (items.length > 0 && periodType === appAutoTopUpPeriodType.LOW_BALANCE) ||
  //       periodType === appAutoTopUpPeriodType.RATE
  //     ) {
  //       setPaymentProducts(
  //         items.filter(({ productType }) => productType === appPaymentProductType.LOW_BALANCE)
  //       );
  //     }

  //     if (items.length > 0 && periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
  //       setPaymentProducts(
  //         items.filter(({ productType }) => productType === appPaymentProductType.RECURRING)
  //       );
  //     }

  //     setAllPaymentProducts(items);
  //     setIsLoading(false);
  //     return items;
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //     showAlert({
  //       type: appAlert.ERROR,
  //       message: t(error.error[0].messageBody)
  //     });
  //     return false;
  //   }
  // };

  const getPaymentProducts = async () => {
    try {
      // setIsPaymentProductLoading(true);
      const {
        data: { items = [] }
      } = await onPaymentProduct();
      if (items.length > 0) {
        setPaymentProducts(
          items.filter(({ productType }) => productType === appPaymentProductType.LOW_BALANCE)
        );
        setIsPaymentProductLoading(false);
        setAllPaymentProducts(items);
        setIsLoading(false);
        return items;
      }
      // setIsLoading(false);
      return items;
    } catch (error) {
      console.log(error);
      setIsPaymentProductLoading(false);
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'nc_generic_err_txt')
      });
      return false;
    }
  };

  const getPaymentMethod = async () => {
    try {
      // setIsPaymentMethodLoading(true);
      const {
        data: { paymentMethods = {} }
      } = await onPaymentMethod();
      // eslint-disable-next-line prefer-const
      let payments = [];
      if (paymentMethods) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(paymentMethods)) {
          payments.push({ name: key, ...value });
          if (key === appPaymentMethods.CREDIT_CARD) {
            payments.push({ name: appPaymentMethods.AMERICAN_EXPRESS, ...value });
          }
        }
        setPaymentMethod(payments);
        setIsPaymentMethodLoading(false);
      }
      return payments;
    } catch (error) {
      console.log(error);
      setIsPaymentMethodLoading(false);
      setIsLoading(false);
      return false;
    }
  };

  const ssoPaymentToken = async ({ clientId, alphacommHash, msisdn, ...params }) => {
    try {
      const ssoPaymentTokenParams = {
        clientId,
        hashCode: alphacommHash,
        language: 'de',
        msisdn,
        pageId: 'home'
      };
      setIsLoading(true);
      // showLoader();
      const { data = {}, success } = await onSsoPaymentToken(ssoPaymentTokenParams);
      if (data && success) {
        setPaymentAuthData(ssoPaymentTokenParams);
        setTimeout(async () => {
          await getPaymentProducts();
          await getPaymentMethod();
        }, 100);
      }
      // hideLoader();
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: t(error.error[0].messageBody)
      });
      console.log('sso payment ---', error);
      return false;
    }
  };

  const overviewFormSubmit = async (values) => {
    try {
      const {
        msisdn,
        puk,
        mnp,
        currentProvider,
        type,
        oldNumber,
        oldNumberPrefix,
        chosenTariffId,
        cscPassword,
        hotlinePassword,
        birthDate,
        emailAddress,
        firstName,
        lastName,
        title,
        additionalInfo,
        city,
        countryCode,
        houseNumber,
        street,
        zip,
        // alternateNumber,
        // alternateNumberPrefix,
        acceptedCreditCheck,
        brandPartnerCustomMarketing,
        employee,
        // marketingMultibrand,
        termsAndConditions,
        thirdParty
      } = values;
      setIsLoading(true);
      // showLoader();
      // eslint-disable-next-line no-unneeded-ternary
      const multiBrand = thirdParty ? true : false;
      // const modifiedAlternateNumber =
      //   alternateNumber.length > 0 ? alternateNumber : msisdn.slice(4);
      // const modifiedAlternateNumberPrefix =
      //   alternateNumberPrefix.length > 0 ? alternateNumberPrefix : msisdn.slice(0, 4);
      const params = mnp
        ? {
            activationData: {
              msisdn,
              puk
            },
            activationMNPData: {
              currentProvider,
              mnp,
              oldNumber: {
                number: oldNumber,
                prefix: oldNumberPrefix.toString()
              },
              type: Number(type)
            },
            chosenTariffId,
            cscPassword,
            hotlinePassword,
            legitimationMethod: 3,
            personalData: {
              address: {
                additionalInfo,
                city,
                countryCode: Number(countryCode),
                houseNumber,
                street,
                zip
              },
              // alternatePhoneNumber: {
              //   number: modifiedAlternateNumber,
              //   prefix: modifiedAlternateNumberPrefix
              // },
              birthDate,
              emailAddress,
              firstName,
              flags: {
                acceptedCreditCheck,
                brandPartnerCustomMarketing,
                employee,
                marketingMultibrand: multiBrand,
                termsAndConditions,
                thirdParty
              },
              lastName,
              salutation: Number(3),
              title: Number(title)
            }
          }
        : {
            activationData: {
              msisdn,
              puk
            },
            chosenTariffId,
            cscPassword,
            hotlinePassword,
            legitimationMethod: 3,
            personalData: {
              address: {
                additionalInfo,
                city,
                countryCode: Number(countryCode),
                houseNumber,
                street,
                zip
              },
              // alternatePhoneNumber: {
              //   number: modifiedAlternateNumber,
              //   prefix: modifiedAlternateNumberPrefix
              // },
              birthDate,
              emailAddress,
              firstName,
              flags: {
                acceptedCreditCheck,
                brandPartnerCustomMarketing,
                employee,
                marketingMultibrand: multiBrand,
                termsAndConditions,
                thirdParty
              },
              lastName,
              salutation: Number(3),
              title: Number(title)
            }
          };

      // ---------------------Activation Step---------------------
      setPersonalDataForm({
        ...params.personalData,
        ...params.personalData.address,
        personalData: params.personalData
      });
      const {
        data = {},
        data: { signupId: clientId = '', alphacommHash = '', postidentUrl = '' }
      } = await onActivateSim(params);

      // const postidentUrl = 'https://postident.deutschepost.de/user/start/?caseId=';
      // const clientId = "";
      // const alphacommHash =
      //   '';
      // const data = {
      //   postidentUrl,
      //   clientId,
      //   alphacommHash
      // };
      if (data) {
        // console.log('Sim Activated --- ', JSON.stringify(data));
        // showAlert({ type: appAlert.SUCCESS, message: 'Sim Activated Successfully..' });
        // showAlert({ type: appAlert.INFO, message: 'Process with payment' });
        // Set Postident & Activation Data To Storage
        await storage.encryptedSetItem(appStorage.ACTIVATION_DATA, JSON.stringify(data));
        await storage.encryptedSetItem(appStorage.POSTIDENT, postidentUrl);
        await storage.encryptedSetItem(appStorage.ACTIVATION_PERSONAL_DATA, JSON.stringify(values));
        setPostIdentUrl(postidentUrl);
        await ssoPaymentToken({
          clientId,
          alphacommHash,
          msisdn
        });
        await getIp();
        // TODO : need to add storage from app config
        setActivateSimSuccessPopup(true);
        setIsLoading(false);
        setTimeout(() => {
          setActivateSimSuccessPopup(false);
          nextStep();
        }, 4000);
      }

      // ---------------------Dummy Step---------------------

      // setPersonalDataForm({ ...params.personalData, ...params.personalData.address });
      // await ssoPaymentToken({
      //   clientId: '2080697263',
      //   alphacommHash:
      //     '0525933b0856343e781818541bd5794b1aa18f39b60c3b1ec2c6a361fc1b76306aec441b6fa05b1ec34615c06dcf42eba817be63252a3de660fa9ab8f18e66ed',
      //   msisdn: '015155908097'
      // });
      // await getIp();
      // setActivateSimSuccessPopup(true);
      // setIsLoading(false);
      // // await storage.encryptedSetItem(appStorage.ACTIVATION_DATA, JSON.stringify(data));
      // // showAlert({ type: appAlert.SUCCESS, message: 'Data Submitted Successfully..' });
      // setTimeout(() => {
      //   setActivateSimSuccessPopup(false);
      //   nextStep();
      // }, 4000);

      return params;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log('onActivateSim error', error?.error[0]?.messageBody);
      showAlert({
        type: appAlert.ERROR,
        message: t(t(error?.error[0]?.messageBody))
      });
      return false;
    }
  };

  const processWithPostIdent = async () => {
    try {
      setIsLoading(true);
      const postident = await storage.encryptedGetItem(appStorage.POSTIDENT);
      if (postIdentUrl) {
        console.log(postIdentUrl);
        // alert('Procedding To PostIdent..');
        window.open(postIdentUrl, '_blank');
        // Remove Postident & Activation Data From Storage
        await storage.encryptedRemoveItem(appStorage.ACTIVATION_DATA);
        await storage.encryptedRemoveItem(appStorage.POSTIDENT);
        // Redirect To Home Page
        window.location.href = '/login';
      } else if (storage.encryptedGetItem(appStorage.POSTIDENT)) {
        // alert('Procedding To PostIdent Via Storage..');
        console.log(postident);
        window.open(postident, '_blank');
        // Remove Postident & Activation Data From Storage
        await storage.encryptedRemoveItem(appStorage.ACTIVATION_DATA);
        await storage.encryptedRemoveItem(appStorage.POSTIDENT);
        setIsLoading(false);
        // Redirect To Home Page
        window.location.href = '/login';
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const onAddToCart = async (values) => {
    try {
      const { chargeTo, productAmount, productId, type, isDirectProduct } = values;

      const { msisdn } = phoneNumberActivationForm;

      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      let option = {};

      if (type === appTopUpType.AUTOMATIC) {
        option = {
          phoneNumber: gettingNumberForGerman(msisdn),
          optionType: selectedProduct.optionType,
          amount: productAmount || selectedProduct.amount,
          lowBalanceOption: {
            lowBalanceLimit: 5,
            tariffEnabled: true
          }
        };
      }

      if (type === appTopUpType.DIRECT) {
        option = {
          phoneNumber: gettingNumberForGerman(msisdn),
          optionType: selectedProduct.optionType
        };

        if (isDirectProduct === 'YES') {
          option.amount = productAmount || selectedProduct.amount;
        }
      }

      const data = {
        items: [
          {
            productId: selectedProduct.id,
            option
          }
        ]
      };

      return await onPaymentAddToCart(data);
    } catch (error) {
      console.log('ADD_TO_CART_ERROR:', error);
      throw error;
    }
  };

  const onCheckoutAddress = async (values, _cartName) => {
    try {
      const { chargeTo, productAmount, productId, type } = values;
      const { msisdn } = phoneNumberActivationForm;

      console.log(personalDataForm);
      const {
        personalData: {
          firstName,
          lastName,
          emailAddress,
          address: { city, countryCode, houseNumber, street, zip }
        }
      } = personalDataForm;
      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      await storage.setItem(appStorage.TOPUP_AMOUNT, productAmount || selectedProduct.amount);
      await storage.setItem(appStorage.TOPUP_TYPE, type);
      // if (customerData && Object.keys(customerData).length > 0) {
      // Creating the object to pass in API
      // eslint-disable-next-line prefer-const

      await storage.setItem(appStorage.CART_NAME, _cartName);

      const checkoutAddressPayload = {
        cartName: _cartName,
        deliveryAddress: {
          city,
          countryCode: 'DE',
          email: emailAddress,
          firstname: firstName,
          lastname: lastName,
          houseNumber,
          ipAddress: ip || '',
          phoneNumber: gettingNumberForGerman(msisdn),
          street,
          zip,
          language: 'de'
        }
      };
      return await onPaymentCheckoutAddress(checkoutAddressPayload);
    } catch (error) {
      console.log('CHECKOUT_ADDRESS_ERROR:', error);
      throw error;
    }
  };

  const onCheckoutStartPayment = async (values, _cartName) => {
    try {
      const { type, paymentMethodName } = values;
      const paymentName =
        paymentMethodName === appPaymentMethods.AMERICAN_EXPRESS
          ? appPaymentMethods.CREDIT_CARD
          : paymentMethodName;

      const inputParams = {
        cartName: _cartName,
        paymentMethod: paymentName,
        returnUrl: env.REACT_APP_SHOPPING_RETURN_URL,
        savePayment: type === appTopUpType.AUTOMATIC
      };
      return await onCheckoutPaymentStart(inputParams);
    } catch (error) {
      console.log('START_PAYMENT_ERROR:', error);
      throw error;
    }
  };

  // Step 6 - Part A (Process addToCart, checkoutAddress & paymentMethod calls)
  const processToPayment = async (values) => {
    setIsLoading(true);
    try {
      console.log(values);
      const {
        data: { cartName: responseCartName = false },
        success: successAddToCart
      } = await onAddToCart(values);

      if (responseCartName && successAddToCart) {
        setCartName(responseCartName);
        const { success: successCheckoutAddress } = await onCheckoutAddress(
          values,
          responseCartName
        );
        if (successCheckoutAddress) {
          const {
            data: { orderNumber: _orderNumber, payerUrl },
            success
          } = await onCheckoutStartPayment(values, responseCartName);
          if (payerUrl && success) {
            await storage.setItem(appStorage.CART_NAME, cartName);
            await storage.setItem(appStorage.ORDER_NUMBER, _orderNumber);
            await storage.setItem(appStorage.PAYMENT_REASON, AutoTopUpFor.ACTIVATION);
            console.log('payerUrl', payerUrl);
            window.open(payerUrl, '_self');
            setIsLoading(false);
          }
        } else {
          // TODO: Show generic error
          setIsLoading(false);
          // setIsGenericError(true);
          showAlert({
            type: appAlert.ERROR,
            message: t(error.error[0].messageBody || 'nc_generic_err_txt')
          });
        }
      } else {
        // TODO: Show generic error
        setIsLoading(false);
        // setIsGenericError(true);
        showAlert({
          type: appAlert.ERROR,
          message: t(error.error[0].messageBody || 'nc_generic_err_txt')
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log('PROCESSING: ', error);
      // setIsGenericError(true);
      showAlert({
        type: appAlert.ERROR,
        message: t(error.error[0].messageBody || 'nc_generic_err_txt')
      });
    }
  };

  // const processToPayment = async ({
  //   productId,
  //   periodType: autoType,
  //   periodAmount,
  //   periodDay,
  //   certainAmount
  // }) => {
  //   try {
  //     setIsLoading(true);
  //     // console.log('productId-------------------', productId);
  //     const selectedProduct = allPaymentProducts.find((data) => data.id === Number(productId));
  //     const { amount } = selectedProduct;
  //     const { msisdn } = phoneNumberActivationForm;
  //     const { minAmount } = getTopUpWithoutAmount(
  //       appAutoTopUpType.AUTOMATIC,
  //       autoType,
  //       allPaymentProducts
  //     );
  //     const optionType = await getLowBalanceTopUpWithoutAmount(allPaymentProducts).optionType;
  //     const lowBalanceLimit = autoType === appAutoTopUpPeriodType.LOW_BALANCE ? 5 : minAmount;

  //     const data = {
  //       productId,
  //       option: {
  //         phoneNumber: gettingNumberForGerman(msisdn),
  //         optionType,
  //         lowBalanceOption: {
  //           lowBalanceLimit,
  //           tariffEnabled: isAutoLowEnabled
  //         },
  //         recurringOption: {}
  //       }
  //     };

  //     const days = {
  //       value: periodDay
  //     };
  //     const params = generateParamsForAddToCart(
  //       appAutoTopUpType.AUTOMATIC,
  //       otherAmount,
  //       data,
  //       autoType,
  //       otherAmount ? '' : amount.toString(),
  //       days !== '' ? days : '',
  //       isAutoLowEnabled
  //     );
  //     // alert('params');
  //     // addToCart Call
  //     const {
  //       data: { cartName: responseCartName = false },
  //       success
  //     } = await onPaymentAddToCart(params);
  //     if (responseCartName && success) {
  //       setCartName(responseCartName);
  //       setProcessToPaymentForm({ ...processToPaymentForm, otherAmount, productId });
  //       setChargingForm({ ...chargingForm, cartName: responseCartName });
  //     }
  //     // Checkout Address In Background
  //     const {
  //       personalData: {
  //         firstName: firstname,
  //         lastName: lastname,
  //         emailAddress: email,
  //         address: { city, countryCode, houseNumber, street, zip }
  //       }
  //     } = personalDataForm;
  //     const { ipAddress, language } = processToPaymentForm;

  //     const checkoutAddressParams = {
  //       cartName: responseCartName,
  //       deliveryAddress: {
  //         city,
  //         countryCode: 'DE',
  //         email,
  //         firstname,
  //         houseNumber,
  //         ipAddress,
  //         language,
  //         lastname,
  //         phoneNumber: gettingNumberForGerman(msisdn),
  //         street,
  //         zip
  //       }
  //     };
  //     // alert('checkoutAddressParams');
  //     // checkoutAddress Call
  //     const { data: checkoutAddressData = {}, success: checkoutAdressSuccess } =
  //       await onPaymentCheckoutAddress(checkoutAddressParams);

  //     // paymentMethod Call
  //     if (checkoutAddressData && checkoutAdressSuccess) {
  //       const {
  //         data: { paymentMethods = {} }
  //       } = await onPaymentMethod();
  //       // eslint-disable-next-line prefer-const
  //       let payments = [];
  //       if (paymentMethods) {
  //         // eslint-disable-next-line no-restricted-syntax
  //         for (const [key, value] of Object.entries(paymentMethods)) {
  //           console.log({ name: key, ...value });
  //           if (value.paymentTokenEnabled) {
  //             payments.push({ name: key, ...value });
  //             if (key === appPaymentMethods.CREDIT_CARD) {
  //               payments.push({ name: appPaymentMethods.AMERICAN_EXPRESS, ...value });
  //             }
  //           }
  //         }
  //         console.log({ payments });
  //         setPaymentMethod(payments);
  //       }
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     showAlert({
  //       type: appAlert.ERROR,
  //       message: t(error.error[0].messageBody)
  //     });
  //     console.log(error);
  //   }
  // };
  // Step 6 Part B (Process payment methods & store all details in the storage)
  const chargingFormSubmit = async ({ paymentMethodName }) => {
    const paymentName =
      paymentMethodName === appPaymentMethods.AMERICAN_EXPRESS
        ? appPaymentMethods.CREDIT_CARD
        : paymentMethodName;
    try {
      setIsLoading(true);
      let savePayment = false;
      if (paymentMethodName === 'sofort' || paymentMethodName === 'paydirekt') savePayment = false;
      else savePayment = true;

      const paymentParams = {
        cartName,
        paymentMethod: paymentName,
        returnUrl: env.REACT_APP_SHOPPING_RETURN_URL,
        savePayment
      };
      // console.log('cart name: ', cartName);
      const { data = {}, success } = await onCheckoutPaymentStart(paymentParams);
      if (data && success) {
        await storage.encryptedSetItem(appStorage.CART_NAME, cartName);
        await storage.encryptedSetItem(appStorage.ORDER_NUMBER, data.orderNumber);
        await storage.encryptedSetItem(appStorage.PAYMENT_REASON, AutoTopUpFor.ACTIVATION);
        setIsLoading(false);
        // showAlert({ type: appAlert.SUCCESS, message: 'Please Process With The Payment..' });
        window.open(data.payerUrl, '_self');
      }
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: t(error.error[0].messageBody)
      });
      return false;
    }
  };

  const onClickBack = () => {
    setCurrentStep(0);
    setPhoneNumberActivationForm(phoneNumberInitialValue);
    setTariffActivationForm(tariffActivationInitialValue);
    setPersonalDataForm(personalDataInitialValue);
    setOverviewForm(overviewInitialValue);
    setIsActivationClicked(false);
  };

  const onFailedPayment = async () => {
    try {
      const aData = await storage.encryptedGetItem(appStorage.ACTIVATION_DATA);
      const aPersonalData = await storage.encryptedGetItem(appStorage.ACTIVATION_PERSONAL_DATA);
      console.log('aData', aData);
      console.log('aPersonalData', aPersonalData);
      // await ssoPaymentToken({
      //   clientId,
      //   alphacommHash,
      //   msisdn: '01705011197'
      // });
      // await getIp();
    } catch (error) {
      console.log(error);
    }
  };

  // We wrap it in a useMemo for performance reason

  const contextPayload = useMemo(
    () => ({
      // States
      currentStep,
      setCurrentStep,
      isLoading,
      setIsLoading,
      bookableTariffs,
      setBookableTariffs,
      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,
      otherAmount,
      setOtherAmount,
      showAddress,
      setShowAddress,
      cartName,
      setCartName,
      orderNumber,
      setOrderNumber,
      paymentUrl,
      setPaymentUrl,
      postIdentUrl,
      setPostIdentUrl,
      paymentMethod,
      setPaymentMethod,
      selectedTariffId,
      setSelectedTariffId,
      simProvider,
      countries,
      setCountries,
      identityTypes,
      setIdentityTypes,
      nationality,
      setNationality,
      areaCode,
      setAreaCode,
      salutations,
      setSalutations,
      isSimInvalid,
      setIsSimInvalid,
      emailModal,
      setEmailModal,
      isChargingClicked,
      setIsChargingClicked,
      activateSimSuccessPopup,
      setActivateSimSuccessPopup,
      showBackButton,
      setShowBackButton,
      isActivationClicked,
      setIsActivationClicked,
      isPaymentProductLoading,
      setIsPaymentProductLoading,
      isPaymentMethodLoading,
      setIsPaymentMethodLoading,
      autoTopupMoreInfo,
      setAutoTopupMoreInfo,

      // Form States
      phoneNumberActivationForm,
      setPhoneNumberActivationForm,
      tariffActivationForm,
      setTariffActivationForm,
      personalDataForm,
      setPersonalDataForm,
      overviewForm,
      setOverviewForm,
      processToPaymentForm,
      setProcessToPaymentForm,
      chargingForm,
      setChargingForm,
      loginPasswordForm,
      emailCodeError,
      setEmailCodeError,
      isAutoLowEnabled,
      setIsAutoLowEnabled,
      changePasswordErrorMsg, 
      setChangePasswordErrorMsg,

      // Form Initial States & Validations
      phoneNumberInitialValue,
      phoneNumberValidations,
      validationSchemaEmail,
      phoneNumberValidationsWithMNP,
      tariffActivationInitialValue,
      tariffActivationValidation,
      personalDataInitialValue,
      personalDataValidations,
      loginPasswordValidation,
      validatePasswordWithKeys,
      overviewInitialValue,
      overviewValidation,
      processToPaymentInitialValue,
      processToPaymentValidations,
      processToProductValidations,
      productValidation,
      chargingInitialValue,
      chargingValidations,

      // Functions
      phoneNumberSubmit,
      verifyEmail,
      verifyEmailCode,
      validationSchemaCode,
      onResendCode,
      phoneNumberActivationFormSubmit,
      tariffActivationFormSubmit,
      personalDataFormSubmit,
      loginPasswordFormSubmit,
      overviewFormSubmit,
      processToPayment,
      chargingFormSubmit,
      getLookup,
      processWithPostIdent,
      onClickBack,
      onTopupError,

      // Stepper Functions
      nextStep,
      prevStep,

      // Custom Validation for step one
      PhoneNumberCustomValidation,
      ssoPaymentToken,
      onFailedPayment
    }),
    [
      // States
      currentStep,
      setCurrentStep,
      isLoading,
      setIsLoading,
      bookableTariffs,
      setBookableTariffs,
      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,
      otherAmount,
      setOtherAmount,
      showAddress,
      setShowAddress,
      cartName,
      setCartName,
      orderNumber,
      setOrderNumber,
      paymentUrl,
      setPaymentUrl,
      postIdentUrl,
      setPostIdentUrl,
      isSimInvalid,
      setIsSimInvalid,
      emailModal,
      setEmailModal,
      isChargingClicked,
      setIsChargingClicked,
      activateSimSuccessPopup,
      setActivateSimSuccessPopup,
      showBackButton,
      setShowBackButton,
      isActivationClicked,
      setIsActivationClicked,
      emailCodeError,
      setEmailCodeError,
      isAutoLowEnabled,
      setIsAutoLowEnabled,
      isPaymentProductLoading,
      setIsPaymentProductLoading,
      isPaymentMethodLoading,
      setIsPaymentMethodLoading,
      autoTopupMoreInfo,
      setAutoTopupMoreInfo,

      // Form States
      phoneNumberActivationForm,
      setPhoneNumberActivationForm,
      tariffActivationForm,
      setTariffActivationForm,
      personalDataForm,
      setPersonalDataForm,
      overviewForm,
      setOverviewForm,
      processToPaymentForm,
      setProcessToPaymentForm,
      chargingForm,
      setChargingForm,
      loginPasswordForm,
      changePasswordErrorMsg, 
      setChangePasswordErrorMsg,

      // Form Initial States & Validations
      phoneNumberInitialValue,
      phoneNumberValidations,
      validationSchemaEmail,
      phoneNumberValidationsWithMNP,
      tariffActivationInitialValue,
      tariffActivationValidation,
      personalDataInitialValue,
      personalDataValidations,
      loginPasswordValidation,
      validatePasswordWithKeys,
      overviewInitialValue,
      overviewValidation,
      processToPaymentInitialValue,
      processToPaymentValidations,
      processToProductValidations,
      productValidation,
      chargingInitialValue,
      chargingValidations,

      // Functions
      phoneNumberSubmit,
      verifyEmail,
      verifyEmailCode,
      validationSchemaCode,
      onResendCode,
      phoneNumberActivationFormSubmit,
      tariffActivationFormSubmit,
      personalDataFormSubmit,
      loginPasswordFormSubmit,
      overviewFormSubmit,
      processToPayment,
      chargingFormSubmit,
      getLookup,
      processWithPostIdent,
      onClickBack,
      onTopupError,

      // Stepper Functions
      nextStep,
      prevStep,

      // Custom Validation for step one
      PhoneNumberCustomValidation,
      ssoPaymentToken,
      onFailedPayment
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <ActivationContext.Provider value={contextPayload}>{children}</ActivationContext.Provider>;
}

ActivationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useActivation = () => useContext(ActivationContext);

export default ActivationContext;
